<template>
  <div class="overall-page">
    <div class="head">
      <div class="head-left">
        <img src="@/assets/img/wang/back.png" @click="back" alt="" />
        <span>详情</span>
      </div>
    </div>
    <div class="page">
      <Breadcrumb :routerList="routerList"></Breadcrumb>
      <!-- 词库信息 -->
      <el-card shadow="never" class="report-info" v-loading="reportLoading">
        <div class="titleWarning">
          <div class="titleWar">
            <Title>词库信息</Title>
          </div>
        </div>
        <div class="cikuxinxi">
        <div class="div"><div class="left">提交机构 : </div>{{myInfo.orgName}}</div>
        <div class="div"><div class="left">提交人 : </div>{{myInfo.saleName}}</div>
        <div class="div"><div class="left">提交时间 : </div>{{myInfo.submitTime}}</div>
  
      </div>
      <img src="@/assets/img/zhang/setting.png" alt="" />
      </el-card>
      <!-- 提交词库 -->
      <el-card shadow="never" class="report-info" v-loading="reportLoading">
        <div class="titleWarning">
          <div class="titleWar">
            <Title>提交词库</Title>
          </div>
        </div>
        <el-form :inline="true" class="demo-form-inline tijiaociku" v-if="myInfo.planMisreExamineWordList && myInfo.planMisreExamineWordList.length > 0">
        <div class="div" v-for="(item, inx) in myInfo.planMisreExamineWordList" :key="inx">
          <el-form-item label="错误词" class="inputBox">
            <el-input disabled  v-model="item.inaccuracyWord" class="input" size="small"></el-input>
          </el-form-item>
          <el-form-item label="正确词" class="inputBox">
            <el-input disabled v-model="item.correctWord"  class="input" size="small"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <Bitmap v-else :defaultValue="'暂无提交词库'" :peizhi="{  'm-t':'0px',
          'p-t':'0px'}"></Bitmap>
      </el-card>
      <!-- 审核结果 -->
      <el-card shadow="never" class="report-info" v-loading="reportLoading">
        <div class="titleWarning">
          <div class="titleWar">
            <Title>审核结果</Title>
          </div>
        </div>
        <div class="my_box">
        <el-input
        disabled
          style="width:65%"
          :autosize="{ minRows: 4, maxRows: 20 }"
          type="textarea"
          placeholder="请输入内容"
          v-model=" myInfo.examineResult"
        />
      </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import Bitmap from "@/components/bitmap.vue";
import Breadcrumb from "@/components/breadcrumb.vue";
import {detail} from '@/api/Thesaurus' 
import Title from "./components/Title.vue";
export default {
  components: { Breadcrumb,Title,Bitmap },
  data() {
    return {
      routerList: ["系统设置", "详情"],
      myInfo: {}, // 详情信息
      id: null,
      reportLoading:false,
      textarea:"",
      findingOfAudit:'',
      tijiaoList: [
        { cuo: "", zheng: "" },
        { cuo: "", zheng: "" },
        { cuo: "", zheng: "" },
        { cuo: "", zheng: "" },
        { cuo: "", zheng: "" },
      ], // 提交词库列表
    };
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.id = params.id;
    }
    this.getdetail();
  },
  mounted() {},
  methods: {
    async getdetail() {
      let Res = await detail(this.id);
      this.myInfo = Res?.data?.data || {};
    },
    back() {
      this.$router.push("/settings");
    },
  },
};
</script>
<style scoped>
/* .el-input{width: 300px;}  */
.el-form-item{margin-bottom: 0;}
</style>
<style lang="scss" scoped>
.tijiaociku {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .div {
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom:24px ;
    .inputBox{
      width: 45%;
      .input{width: 280px;}
    }
    span{color: red;cursor: pointer;}
  }
}
.my_box {
  margin-top: 15px;
  width: 100%;
  padding: 20px 20px;
  border-radius: 5px;
  background: #f5f6fa;
} 
.cikuxinxi{
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 20px;
    margin-left:20px;
    
    .div{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        color: #666666;
        font-size: 14px;
        .left{
            color: #333333;
            font-weight: 600;
            width: 80px;
        }
    }
}
    .report-info {
      width: 100%;
      height: auto;
      background: #ffffff;
      border-radius: 4px;
      margin-top:20px ;
      position: relative;
      .reportDis {
        .report-report {
          display: flex;
          align-items: center;
          span {
            color: #606266;
            font-size: 14px;
            font-weight: normal;
          }
        }
      }
      .titleWarning {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .titleWar {
          display: flex;
          align-items: center;
          .bianji {
            margin-left: 25px;
            color: #ff7c00;
            font-size: 0.875rem;
            position: relative;
            top: 3px;
            cursor: pointer;
            span {
              margin-left: 8px;
            }
          }
        }

        .button {
          display: flex;
          .search {
            display: flex;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            width: auto;
            background: #ffffff;
            border-radius: 4px;
            padding: 6px 16px;
            cursor: pointer;
            border: 1px solid #e1e1e1;
          }
          .reset {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 32px;
            background: #f58030;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            cursor: pointer;
            margin-left: 15px;
          }
        }
      }
      .report-report {
        display: flex;
        margin-top: 40px;
        margin-left: 41px;
        span {
          color: #e60b1e;
          display: inline-block;
          padding: 2px 4px 0 0;
          font-weight: 600;
          line-height: 36px;
        }
        .report-title {
          min-width: 106px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 36px;
          text-align-last: justify;
          margin-right: 18px;
        }
        .el-radio-group {
          font-size: 0;
          margin-top: 11px;
        }
        ::v-deep .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 351px;
          height: 36px;
        }
        ::v-deep .el-date-editor .el-range__icon {
          margin-top: -5px;
        }
        ::v-deep .el-icon-time:before {
          content: "\e78e";
        }
        .report-textarea {
          width: 351px;
        }
      }
      .report-report:nth-child(1) {
        margin-top: 51px;
      }
      img{
        position: absolute;
    right: 0;
    bottom: 0;
      }
    }
.overall-page {
  width: 100%;
  .head {
    display: flex;
    width: 100%;
    height: 56px;
    background: #ffffff;
    position: fixed;
    margin-top: 0;
    z-index: 100;
    .head-left {
      display: flex;
      height: 100%;
      width: 50%;
      align-items: center;
      img {
        height: 38px;
        width: 38px;
        margin-left: 23px;
        margin-right: 15px;
        cursor: pointer;
      }
      span {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
      }
    }
  }
  .page {
    padding-top: 72px;
    .info-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 16px;
      .head-card {
        display: flex;
        align-items: center;
        width: 100%;
        border: 1px solid #e4e6ec;
        .head-card-left {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          height: auto;
          width: 200px;
          .shadow {
            height: 74px;
            width: 57px;
          }
        }
        .head-card-right {
          display: flex;
          flex-direction: column;
          width: calc(100% - 370px);
          margin: 40px 0px 32px 0px;
        }
        .title-box {
          display: flex;
          width: 100%;
          min-height: 42px;
          .title-box-left {
            display: flex;
            align-items: flex-start;
            height: 100%;
            width: 58px;
            margin-right: 16px;
            div {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 58px;
              height: 24px;
              background: #ffedef;
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ea3342;
              white-space: nowrap;
              margin-top: 10px;
            }
          }
          .title-box-right {
            width: calc(100% - 60px);
            min-height: 42px;
            line-height: 42px;
            font-size: 30px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }
        .early-warning {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;
          margin: 16px 0px;
          .header-img {
            height: 50px;
            width: 52px;
            border-radius: 50%;
            margin-right: 24px;
          }
          .warning-type {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
            height: 24px;
            width: auto;
            background: #ff4b1f;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-right: 16px;
          }
          .platform-type {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
            height: 24px;
            width: auto;
            background: #f58030;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-right: 16px;
          }
          .read {
            width: 17px;
            height: 15px;
            margin-right: 8px;
          }
          .text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #02bc7c;
            margin-right: 16px;
          }
          .time {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
        }
        .operation {
          display: flex;
          height: 20px;
          width: auto;
          .copy {
            display: flex;
            align-items: center;
            height: 20px;
            width: auto;
            cursor: pointer;
            margin-right: 41px;
            img {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
            span {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
        }
        .shadow2 {
          height: 100%;
          width: 170px;
        }
        .disposed {
          height: 114px;
          width: 129px;
          position: absolute;
          margin-left: calc(100% - 386px);
        }
      }
      .platform {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 1164px;
        height: auto;
        background: #f4f5fa;
        padding: 24px 24px 0px 32px;
        margin: 24px 0px 24px 0px;
        .img {
          height: 110px;
          width: 110px;
          position: absolute;
          margin-left: 1000px;
          margin-bottom: 24px;
        }
        .platform-list {
          display: flex;
          height: 20px;
          width: 100%;
          margin-bottom: 24px;
          .platform-time {
            display: flex;
            align-items: center;
            height: 20px;
            width: 50%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            img {
              height: 16px;
              width: 17px;
              margin-left: 10px;
              margin-top: 3px;
              cursor: pointer;
            }
          }
        }
        .platform-name {
          display: flex;
          align-items: center;
          height: 20px;
          width: 100%;
          margin-bottom: 24px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          img {
            height: 16px;
            width: 17px;
            margin-left: 10px;
            margin-top: 3px;
            cursor: pointer;
          }
        }
      }
      .content {
        width: 1164px;
        height: auto;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-top: 20px;
        line-height: 25px;
        text-indent: 25px;
      }
    }
    .be-similar {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
      padding: 40px 240px 26px 240px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 16px;
      .be-similar-account {
        display: flex;
        align-items: center;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-bottom: 10px;
        span:nth-child(1) {
          z-index: 10;
        }
        span:nth-child(2) {
          font-size: 14px;
        }
        .be-similar-color {
          width: 77px;
          height: 6px;
          background: linear-gradient(90deg, #f89d55 0%, #f4640c 100%);
          position: absolute;
          margin-left: 0px;
          margin-top: 22px;
          z-index: 9;
        }
      }
      .data-box {
        display: flex;
        width: 100%;
        height: auto;
        padding: 24px 0px;
        border-bottom: 1px solid #e4e6ec;
        .data-box-left {
          width: 104px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            height: 56px;
            width: 56px;
            border-radius: 50%;
          }
          .platform-type {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 24px;
            width: auto;
            background: #f58030;
            max-width: 104px;
            padding: 0px 22px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-top: 9px;
          }
        }
        .data-box-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          height: 100%;
          .name-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: auto;
            margin-bottom: 16px;
            .name-box-left {
              display: flex;
              align-items: center;
              width: calc(100% - 170px);
              height: auto;
              span {
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto;
                height: 24px;
                padding: 0px 8px;
                background: #ff4b1f;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #ffffff;
                margin-right: 16px;
              }
              .name {
                height: 22px;
                width: calc(100% - 200px);
                line-height: 22px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                line-clamp: 1;
                -webkit-box-orient: vertical;
              }
            }
            .time {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              height: 100%;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
            .bad-num {
              display: flex;
              justify-content: flex-end;
              font-size: 14px;
              width: 20%;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #e60b1e;
            }
          }
          .data-info {
            display: flex;
            height: 20px;
            width: 100%;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            .status {
              color: #02bc7c;
              margin-right: 46px;
            }
            .region {
              max-width: 180px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }
            .subject-type {
              margin-right: 46px;
            }
            .company {
              max-width: 300px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              margin-right: 46px;
            }
            .fnas {
              margin-right: 46px;
            }
          }
        }
      }
    }
  }
}
.yujin {
  background: #ffedef;
  font-size: 14px;
  color: #ea3342;
  font-weight: 500;
  padding: 4px 8px;
}
.bad-num {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2e59ec;
  margin-left: 18px;
  cursor: pointer;
  img {
    height: 16px;
    width: 18px;
    margin-right: 8px;
  }
}
</style>
